<template>
    <div>
      <div>  
        <div class="form-group">
          <label for="exampleFormControlSelect1"
            ><h5 style="width: 200px">
              <i class="bi bi-person-plus"></i> 패밀리 출석 현황
            </h5></label
          >
          <select
            class="form-control"
            id="holiday"
            name="holiday"
            v-model="holiday"
            @change="getStudentList()"
          >
            <option id="" value="00">날짜를 선택하세요.</option>
            <option
              v-for="daylist in dayLists"
              :key="daylist.dday"
              v-bind:value="daylist.dday"
              :id="daylist.dday"
              :selected="daylist.dday == daylist.cday"
            >
              {{ daylist.dday }}
            </option>
          </select>
        </div>
      </div>
  
      <div>
        <select
          class="form-control"
          id="gradegroup"
          name="gradegroup"
          v-model="gradegroup"
          @change="getGradeGroup()"
          v-show="this.role > 4"
        >
          <option id="" value="00">조회 대상을 선택하세요.</option>
          <option
            v-for="gradelist in gradegrouplist"
            :key="gradelist.grade"
            v-bind:value="gradelist.grade"
            :id="gradelist.grade"
          >
            {{ gradelist.gradename }}
          </option>
        </select>
      </div>
      <p></p>
  
      <div>
        <ul class="list-group" v-if="memberLists.length > 0">
          <li
            class="list-group-item justify-content-between align-items-center"
            v-for="memberList in memberLists"
            :key="memberList.no"
            style="padding-left: 0.5rem; padding-right: 0.1rem"
          >
            <div class="row">
              <div class="col-5">
                <b>
                  <div class="row">
                    <div class="col-sm-5">
                      <div
                        v-if="memberList.photo && memberList.photo.length > 0"
                        class="form-group mb-2"
                      >
                        <img
                          :src="photourl(memberList.photo)"
                          alt="..."
                          class="img-thumbnail"
                          style="width: 50%"
                        />
                      </div>                
                      <div
                        v-else
                        class="form-group mx-sm-3 mb-2"
                        style="text-align: left"
                      >
                        <img src="../assets/noimage.png" style="width: 40%" />
                      </div>
                    </div>
                    <div class="col-sm-7">
                      <span class="align-middle"
                        >{{ memberList.name }}({{ getLastFourDigits(memberList.phone) }})</span
                      >
                    </div>
                  </div>
                </b>
              </div>
              <div class="col-7" style="text-align: right;">
                <span style="padding-left: 0px" v-if="memberList.attendyn == 'Y'">
                  <button
                    class="btn btn-primary btn-sm"
                  >
                    예배
                  </button>
                </span>
                <span style="padding-left: 0px" v-else>
                  <button                    
                    class="btn btn-secondary btn-sm"
                  >
                  예배
                  </button>
                </span>
                <span style="padding-left: 2px" v-if="memberList.cellattendyn == 'Y'">
                  <button
                    type="button"
                    class="btn btn-warning btn-sm"
                  >
                    셀모임
                  </button>
                </span>
                <span style="padding-left: 2px" v-else>
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                  >
                  셀모임
                  </button>
                </span>              
              </div>
            </div>
          </li>
        </ul>
  
        <ul class="list-group" v-else>
          <div class="row" style="width: 100%; text-align: center">
            <div class="col-12">조회된 자료가 없습니다.</div>
          </div>
        </ul>
        <!-- <button class="m-3 btn btn-sm btn-danger" @click="removeAllTutorials">
              Remove All
            </button> -->
      </div>
    </div>
  </template>
  
  <script>
    import manageService from "../services/manageService";
   
    export default {
      name: "list",
      component: {},
      data() {
        return {
          dayLists: {},
          pageTotalCount: 0,
          page: 15,
          holiday: "00",
          currentDept: "",
          memberLists: {},
          id: this.$store.state.id,
          grade: this.$store.state.grade,
          phone: this.$store.state.phone,
          name: this.$store.state.name,
          teacherid: this.$store.state.teacherid,
          role: this.$store.state.role,
          token: this.$store.state.token,
          classid: this.$store.state.classid,
          studentid: this.$store.state.studentid,
          form: {
            studentid: "",
            teacherid: "",
            no: "",
            attenddate: "",
            pray: "",
            name: "",
            sex: "",
            classid: "",
            groupid: "",
            grade: "",
            report: "",
          },
          isNew: false,
          firstselect: "selected",
          gradegrouplist: {},
          gradegroup: "00",
        };
      },
      computed: {
        photourl() {
          return (url) => {
            return "./upload/photo/" + url;
          };
        },
      },
      methods: {
        //전화번호 뒤4자리만
        getLastFourDigits(tel) {
          return tel ? tel.slice(-4) : '';
        },       
        getGradeGroup() {
          if (this.holiday === "00") {
            this.$toast.show("날짜를 먼저 선택해주세요.", {
              type: "warning",
              position: "top-right",
            });
            //this.gradegroup = "00";
            //return;
          }
  
          const data = {
            attenddate: this.holiday,
            grade: this.gradegroup,
            teacherid: this.form.teacherid,
            role: this.role,
            classid: this.classid,            
          };
  
          manageService
            .getAttendanceList(data)
            .then((response) => {
              this.memberLists = response.data;
              //console.log('this.memberLists', this.memberLists);
            })
            .catch((e) => {
              console.log(e);
            });
        },
        getGradeGroupList() {
          const ndata = {
            classid: this.classid,
            grade: this.gradegroup,
            role: this.role,
          };
  
          // 학년 반을 선택하세요.
          manageService
            .getGradeListAll(ndata)
            .then((response) => {
              //this.manageLists = response.data;
              this.gradegrouplist = response.data;
            })
            .catch((e) => {
              console.log(e);
            });
        },
        //개행문자 인식
        getHtmlConvert(str) {
          const replacer = new RegExp("\\n", "g");
          return str.replace(replacer, "<br />");
        },
        getDayList() {
          manageService
            .getDaysList()
            .then((response) => {
              this.dayLists = response.data.slice(0, this.page);
              //console.log('this.dayLists', this.dayLists);
            })
            .catch((e) => {
              console.log(e);
            });
        },
        //전체 리스트 가져오기
        getStudentList() {
          //if( this.gradegroup === "00") return;
  
          const data = {
            attenddate: this.holiday,
            grade: this.grade,
            teacherid: this.studentid,          
            role: this.role,
            classid: this.classid,
            studentid: this.studentid,
          };
         
          manageService
            .getAttendfamilyList(data)
            .then((response) => {
              this.memberLists = response.data;     
              //console.log("this.memberLists == ",this.memberLists);      
            })
            .catch((e) => {
              console.log(e);
            });
  
        },
      },
      created() {
        this.getDayList();    
      },
    };
  </script>
  
  <style>
    .list {
      text-align: left;
      max-width: 750px;
      margin: auto;
    }
    .done {
      background: oldlace;
    }
  </style>
  